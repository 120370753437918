<template>
    <v-form @submit.prevent="createLmsSubmit" :id="`create-lms-form-mango`" scrollable>
    <v-stepper v-model="e1" alt-labels>
        <v-stepper-header>


            <v-stepper-step
                :complete="e1 > 1"
                step="1"
            >
                รูปเบบการสร้าง
            </v-stepper-step>

            

            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 2"
                step="2"
            >
                เลือก Section
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 3"
                step="3"
            >
                ตังอย่างผลลัพธ์
            </v-stepper-step>

      
        </v-stepper-header>

        <v-stepper-items
       >
      

        <v-stepper-content step="1">
            <v-card outlined class="mb-2" >
                <v-row >
                    <v-col class="d-flex justify-center" style="flex-wrap: wrap;">
                        <template>
                            <v-checkbox
                                class="ml-2 pt-5"
                                v-model="selectionOptionCreated"
                                label="รวม section"
                                value="sumAll"
                                :key="sumAll"
                            ></v-checkbox>

                            <v-checkbox
                                class="ml-2 pt-5"
                                v-model="selectionOptionCreated"
                                label="เเยก section"
                                value="splitAll"
                                :key="splitAll"
                            ></v-checkbox>
                        </template>
                    </v-col>
                </v-row>
            </v-card>

            <v-row>
                <v-col>
                    <v-btn text @click="validatePrevious(0)">
                    Back
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right ">
                    <v-btn
                      color="success"
                      @click="validateNextStepper(1,2)"
                      :key="`submit-btn-${courseData._id}`"
                    >
                      Next
                    </v-btn>
                </v-col>

                
            </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
            <v-row class="mb-2">
                <v-col  v-if="selectionOptionCreated === 'sumAll'" class="d-flex justify-center" style="font-size: small; font-weight: 500; flex-wrap: wrap;">
                    คำอธิบาย : section ที่ท่านเลือกต่อไปนี้จะถูกสร้างรวมกันเป็น class เดียว
                    เช่น วิชา ABC เลือกสร้าง section 001 , 002 จะถูกรวมกันเป็น ABC (Sec.001,002) 
                </v-col>
                <v-col  v-else class="d-flex justify-center" style="font-size: small; font-weight: 500; flex-wrap: wrap;">
                    คำอธิบาย : section ที่ท่านเลือกต่อไปนี้จะถูกสร้างแยกเป็นแต่ละ class
                    เช่น วิชา ABC เลือกสร้าง section 001 , 002 จะถูกแยกเป็น ABC Sec.001 และ ABC Sec.002
                </v-col>
            </v-row>
            <v-card  outlined class="mb-2" style="overflow-x: hidden;  max-height: 200px;" >
               
                <v-row >
                    <v-col class="d-flex justify-center" style="flex-wrap: wrap;">
                       
                        <template>
                        <v-checkbox
                            class="mt-0 pt-5"
                            v-model="selectAll"
                            label="เลือกทั้งหมด"
                            value="checkAll"
                        ></v-checkbox>
                        <div class="break"></div>
                        </template>
                        <template v-for="(section,i) in sectionArr">
                            <v-checkbox
                                style="width: 4rem;"
                                class="mt-0"
                                v-model="sectionSelected"
                                :label="filterSection(section)"
                                :value="section"
                                :key="`checkbox-${i}`"
                            ></v-checkbox>
                            <div class="break" :key="`break-${i}`"></div>
                        </template>
                    </v-col>
                </v-row>
            </v-card>

            <v-row>
                <v-col>
                    <v-btn text @click="validatePrevious(1)">
                    Back
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right ">
                    <v-btn
                      color="success"
                      @click="validateNextStepper(2,3)"
                      :key="`submit-btn-${courseData._id}`"
                    >
                      Next
                    </v-btn>
                </v-col>
                <!-- <v-col class="text-right">
                    <v-btn
                        v-show="btnSubmit"
                        color="primary"
                        type="submit"
                        :form="`create-lms-form-${courseData._id}`"
                        :key="`submit-btn-lms-${courseData._id}`"
                    >
                    Submit
                    </v-btn>
                </v-col> -->
            </v-row>
        </v-stepper-content>
        <v-stepper-content step="3"> 
            <v-card  outlined class="mb-2"  >
                <v-row style="overflow-x: overlay; min-height: 200px;">
                  
                    <v-col class="p-2">
                        
                        <div v-for="(section, i) in previewCreate" :key="i">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-circle-small</v-icon>
                                        {{`${courseData.title} (${section})`}}
                                    </v-list-item-title>
                                  
                                </v-list-item-content>
                               
                            </v-list-item>
                        </div>
                    </v-col>
                    
                   
                </v-row>
            </v-card>

            <v-row class="mt-3">
                <v-col>
                    <v-btn text @click="validatePrevious(2)">
                    Back
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                
                <v-col class="text-right">
                    <v-btn
                        v-show="btnSubmit"
                        color="primary"
                        type="submit"
                        :form="`create-lms-form-mango`"
                        :key="`submit-btn-lms-mango`"
                    >
                    Submit
                    </v-btn>
                </v-col>
            </v-row>
        </v-stepper-content>
       
        </v-stepper-items>
    </v-stepper>
    </v-form>
</template>
<script>
    const config = require(`@/config`)
    import Swal from 'sweetalert2'

    export default {
        name: 'CreateNewLmsForm',
        props: {
            termSelected:String,
            courseData: {
                type: Object
            },
            openModal: Boolean,
            mangoGrant: Boolean
        },
        computed: {
            selectAll: {
                get: function () {
                    // return this.sectionArr ? this.selected.length == this.sectionArr.length : false
                    return true
                },
                set: function (value) {
                    let selected = []

                    if (value) {
                        this.sectionArr.forEach(function (section) {
                        selected.push(section)
                        })
                    }

                    this.sectionSelected = selected
                }
            },
            
            
        },
        created () {
            this.createSectionArr(this.courseData)
            this.btnSubmit = true
        },
        watch: {
            courseData: function (newVal) {
                // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                this.createSectionArr(newVal)
            },
           
        },
        data () {
            return {
                e1: 1,
                currentStep: "lmsType",
                sectionSelected: [],
                sectionArr: [],
                lmsSelected: "mango",
                selectionOptionCreated:"",
                termMango : config.lmsConfig.currentSemester , 
                btnSubmit : true , 
                previewCreate : [] , 
        
            }
        },
        methods: {
            validateNextStepper (current, next) {
                switch (next) {
                    case 3 :
                        if(this.sectionSelected.length > 0){
                            this.previewCreate = this.createPreview()
                            this.e1 = next 
                        }
                        break;
                    case 2 :
                        if(this.selectionOptionCreated){
                            this.e1 = next
                        }
                        break;
                    default:
                        break;
                }
            },
            validatePrevious (back) {
                switch (back) {
                    case 0:
                        this.$emit('backDialog', true)
                        break
                    case 1:
                        this.e1 = back
                        break
                    case 2:
                        this.e1 = back
                        break
                    case 3:
                        this.e1 = back
                        break
                    default:
                        break
                }
            },
            createSectionArr (courseData) {
                if(courseData.sections){
                    this.sectionArr = []
                    courseData.sections = courseData.sections.sort()
                    for(let i=0;i<courseData.sections.length;i++){
                    this.sectionArr.push(courseData.sections[i])
                    }
                }
            },
            sectionAfterTitleName (sectionArr) {
                let sectionTmp = []
                for(let i=0;i<sectionArr.length;i++){
                    sectionTmp.push(this.filterSectionArr(sectionArr[i]))
                }
                return sectionTmp
            },
            filterSectionArr (section) {
                // console.log("filter section:",section)
                let sectionLec = section.substring(0, 3)
                let sectionLab = section.substring(3)
                if (sectionLec === '000' && sectionLab !== '000') {
                    return `Lab.${sectionLab}`
                }else if(sectionLec !== '000' && sectionLab === '000'){
                    return `Lec.${sectionLec}`
                }
                return section
            },
            filterSection (section) {
                let sectionLec = section.substring(0, 3)
                let sectionLab = section.substring(3)
                if (sectionLec === '000' && sectionLab !== '000') {
                return `Lab.${sectionLab}`
                }else if(sectionLec !== '000' && sectionLab === '000'){
                return `Lec.${sectionLec}`
                }
                return 0
            },
            createPreview(){
                
                console.log("preview" , this.selectionOptionCreated , this.sectionSelected)
                if(this.selectionOptionCreated === "sumAll"){
                    // console.log("create map sumall" , this.sectionAfterTitleName(this.sectionSelected))
                    return  [this.sectionAfterTitleName(this.sectionSelected)]
                    
                }else{
                    let sectionShow = []
                    for(let i = 0 ; i < this.sectionSelected.length; i++){
                        // console.log("map" , this.filterSection(this.sectionSelected[i]))
                        sectionShow.push(this.filterSection(this.sectionSelected[i]))
                    }
                    console.log("create map spiltall", sectionShow)
                    return sectionShow
                    
                }

            },
            createLmsSubmit () {
                // console.log(this.sectionSelected,this.lmsSelected)
                if(this.sectionSelected.length > 0 ){
                    this.btnSubmit = false
                    const tempSelectOption ={
                        "service" : this.lmsSelected ,
                        "option" : this.selectionOptionCreated
                    }
                    const createData = {
                        "courseId": this.courseData._id,
                        // "service": this.lmsSelected,
                        "termId" : config.lmsConfig.mango.termId,
                        "section": this.sectionSelected
                    }
                    this.e1 = 1
                    this.currentStep = "lmsType"
                    this.sectionSelected = []
                    this.lmsSelected =""
                    this.selectionOptionCreated = ""
                    this.$emit('backDialog', true)
                    // console.log("this.sectionSelected :>" , this.sectionSelected , this.selectionOptionCreated , this.e1 )
                    // console.log("createLmsSubmit:>" , createData , tempSelectOption)
                    this.$emit('modalSubmit', createData , tempSelectOption)
                    
                }else{
                    Swal.fire({
                        icon: 'warning',
                        title: 'กรุณาเลือก Section',
                    })
                }
            }
        }
    }
</script>